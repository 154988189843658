import { Card } from "@/components/ui/card";

interface PictureCardProps {
  src: string;
  title: string;
}

export const PictureCard: React.FC<PictureCardProps> = ({ src, title }) => {
  return (
    <Card className="bg-popover shadow-2xl rounded-2xl">
      <div className="flex flex-col items-center pb-4">
        <img src={src} alt="" className="mx-6 mt-8 mb-4" />
        <div className="text-2xl my-2">{title}</div>
      </div>
    </Card>
  );
}
