import { PictureCard } from "@/components/picture-card"
import { AscendaButton } from "@/components/ascenda-button";
import travel_image from "@/images/travel.png"
import gift_card_image from "@/images/gift_card.png"
import traveler_image from "@/images/traveler.png"
import cashback_image from "@/images/cashback.png"
import admin_icon from "@/images/admin.svg"
import next_door_icon from "@/images/next_door.svg"
import { useNavigate } from "react-router-dom";

export function WelcomePage() {
  const navigate = useNavigate();

  function onDemoButtonClick() {
    navigate('/');
  }

  function onAdminDemoButtonClick() {
    window.location.assign("https://demo1.admin.us.ascenda.com");
  }

  return (
    <div className="flex flex-col items-center justify-center h-full space-y-20">
      <div className="w-2/3">
        <div className="font-semibold leading-snug text-center text-primary text-7xl">
          Launch your Premium Rewards program here
        </div>
      </div>
      <div className="flex space-x-10">
        <PictureCard src={ travel_image } title="Travel" />
        <PictureCard src={ traveler_image } title="Points Transfer" />
        <PictureCard src={ gift_card_image } title="Gift Cards" />
        <PictureCard src={ cashback_image } title="Cashback" />
      </div>
      <div className="flex space-x-6">
        <AscendaButton
          onClick={onAdminDemoButtonClick}
          content="Admin demo"
          rightIcon={<img src={admin_icon} alt="" className="inline pl-4" />}
          loading={false}
          size="extralg"
        />
        <AscendaButton
          onClick={onDemoButtonClick}
          content="Ascenda demo"
          rightIcon={<img src={next_door_icon} alt="" className="inline pl-4" />}
          size="extralg"
          loading={false}
          variant="outline"
        />
      </div>
    </div>
  );
}
