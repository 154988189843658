import { COUNTRIES, SUPPORTED_RC_COUNTRY_CODES } from "./data/countries";
import { CountryData } from "@/lib/types";

const GenericEmailDomain = [
  "gmail.com",
  "outlook.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "yahoo.com.au",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "yahoo.co.jp",
  "sky.com",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "yahoo.com.ar",
  "tiscali.co.uk",
  "yahoo.com.mx",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "yahoo.co.id",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "yahoo.com.sg",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au",
];

const Countries: CountryData[] = COUNTRIES.filter((country) =>
  SUPPORTED_RC_COUNTRY_CODES.has(country.iso2),
);

const Currencies = [
  "USD",
  "CAD",
  "EUR",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ARS",
  "AUD",
  "AZN",
  "BAM",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BND",
  "BOB",
  "BRL",
  "BWP",
  "BYN",
  "BZD",
  "CDF",
  "CHF",
  "CLP",
  "CNY",
  "COP",
  "CRC",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EEK",
  "EGP",
  "ERN",
  "ETB",
  "GBP",
  "GEL",
  "GHS",
  "GNF",
  "GTQ",
  "HKD",
  "HNL",
  "HRK",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KHR",
  "KMF",
  "KRW",
  "KWD",
  "KZT",
  "LBP",
  "LKR",
  "LTL",
  "LVL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MOP",
  "MUR",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SDG",
  "SEK",
  "SGD",
  "SOS",
  "SYP",
  "THB",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "XAF",
  "XOF",
  "YER",
  "ZAR",
  "ZMK",
  "ZWL",
];

const UrlSafeStringPattern = /^[a-zA-Z0-9-]*$/;
const DomainUrlPattern =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

const MCDuplicateMessage = `It seems there's already a rewards program associated with your company.
If this issue persists, please reach out to our support team at <u>rewardscentral@support.ascenda.com</u>
`;

// TODO: Remove this after M2020 October 2024
const HighPriorityDemoCompanyWebsite = [
  'adyen.com',
  'airwallex.com',
  'bankofamerica.com',
  'citi.com',
  'citigroup.com',
  'wellsfargo.com',
  'investmentbank.barclays.com',
  'jpmorgan.com',
  'jpmorganchase.com',
  'morganstanley.com',
  'apple.com',
  'squareup.com',
  'stripe.com',
  'visa.com',
  'synchrony.com',
  'wellsfargo.com',
  'americanexpress.com',
  'pismo.io',
  'usaa.com',
  'chime.com',
  'acrisure.com',
  'axosbank.com',
  'bill.com',
  'breadfinancial.com',
  '53.com',
  'firstcitizens.com',
  'gmfinancial.com',
  'key.com',
  'klarna.com',
  'payoneer.com',
  'paypal.com',
  't-mobile.com',
  'td.com',
  'truist.com',
  'ally.com',
  'home.barclays',
  'revolut.com',
  'ramp.com',
  'thecitizensbank.net',
  'capitalone.com',
  'fnbo.com',
  'navyfederal.org',
  'thepointsguy.com',
  'plaid.com',
  'firstnational.com',
  'www3.mtb.com',
  'regions.com',
  'bk.mufg.jp',
  'im.bnymellon.com',
  'fleetcor.com',
  'progressive.com',
  'prudential.com',
  'goldmansachs.com',
  'corpay.com',
  'cnbcorinth.com',
  'fisglobal.com',
  'creditonebank.com',
  'lendingclub.com',
  'sellersfi.com',
  'digital.isracard.co.il',
  'bb.com.br',
  'current.com',
  'mercury.com',
  'bluevine.com',
  'garanti.com.tr',
  'greendot.com',
  'hsbc.com',
  'affirm.com',
  'coastpay.com',
  'santanderbank.com',
  '1stsource.com',
  'banco.bradesco',
  'cora.com.br',
  'db.com',
  'equalsmoney.com',
  'fhb.com',
  'hancockwhitney.com',
  'johnsonfinancialgroup.com',
  'liveoakbank.com',
  'meliopayments.com',
  'members1st.org',
  'midflorida.com',
  'nbhbank.com',
  'PLS247.com',
  'quontic.com',
  'renasantbank.com',
  'southstatebank.com',
  'synovus.com',
  'tabbank.com',
  'tento.co',
  'terrabank.com',
  'texascapitalbank.com',
  'tillo.io',
  'toss.im',
  'transpecosbanks.com',
  'travelex.co.uk',
  'trip-arc.com',
  'jointriple.com',
  'tfin.com',
  'utb.com',
  'westernalliancebancorporation.com',
  'wexinc.com',
  'wolfe.com',
  'workwave.com',
  'wowbrand.com',
  'youlend.com',
  'zenbusiness.com',
  'zolve.com',
  'joinadro.com',
  'atb.com',
  'bankingcircle.com',
  'ondeck.com',
  'ubs.com',
  'tribepayments.com',
  'unit.co',
  'investor.vanguard.com',
  'bmo.com',
  'capitalmarkets.bmo.com',
  'curve.com',
  'smbc.co.jp',
  'franklinresources.com',
  'manulife.com',
  'robinhood.com',
  'stonex.com',
  'discoverglobalnetwork.com',
  'pay.com.au',
  'societegenerale.com',
  'ci.natwest.com',
  'tmhcc.com',
  'tdsecurities.com',
  'remitly.com'
].map(str => str.toLowerCase())

export {
  GenericEmailDomain,
  Currencies,
  Countries,
  UrlSafeStringPattern,
  DomainUrlPattern,
  MCDuplicateMessage,
  HighPriorityDemoCompanyWebsite
};
