import ascenda_banner_for_sg_fintech_festival from "@/images/ascenda_banner_for_sg_fintech_festival.svg";

interface FormContainderProps {
  children?: React.ReactNode;
}

export const FormContainder: React.FC<FormContainderProps> = ({ children }) => {
  return (
    <div className="h-full flex">
      <div className="max-lg:hidden h-full">
        <img src={ascenda_banner_for_sg_fintech_festival} className="h-full" alt="" />
      </div>
      <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-4">
        {children}
      </div>
    </div>
  );
};
