import React from "react";
import { AppRouter } from "./routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { FlagProvider } from '@unleash/proxy-client-react';
const PRODUCTION_UNLEASH_KEY =
  'default:production.f06c35434174dcca96e6e275bfff232c863a051339a6c3c6d71e3aa4';
const STAGING_UNLEASH_KEY =
  'default:development.73f429e524f7da4c3366a0ee3fc2ac14333cb58af7070e6391d94de1';

const config = {
  url: 'https://us.app.unleash-hosted.com/usee0016/api/frontend',
  clientKey: import.meta.env.VITE_RAILS_ENV === 'production' ? PRODUCTION_UNLEASH_KEY : STAGING_UNLEASH_KEY,
  refreshInterval: 60,
  appName: 'tenant-hub',
};

const queryClient = new QueryClient();

export function App() {
  return (
    <FlagProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {AppRouter}
        <Toaster />
      </QueryClientProvider>
    </FlagProvider>
  );
}
