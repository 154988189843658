import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { MouseEventHandler } from "react";

interface AscendaButtonProps {
  content: string;
  rightIcon?: React.ReactNode;
  size?: string;
  variant?: string;
  loading: boolean;
  link?: string;
  onClick: () => void;
}

export const AscendaButton: React.FC<AscendaButtonProps> = ({
  rightIcon,
  content,
  loading,
  variant,
  size,
  onClick
}) => {
  return (
    <Button
      onClick={onClick}
      size={size ? size : "lg"}
      variant={variant ? variant : "default"}
      type="submit"
      className="flex max-sm:space-x-[60%] max-lg:w-full"
      disabled={loading}
    >
      <div>{content}</div>
      {!loading && rightIcon}
      {loading && <Loader2 className="w-4 h-4 ml-2 animate-spin" />}
    </Button>
  );
};
