import { AlertDialog, AlertDialogContent } from "@/components/ui/alert-dialog";
import loadingStateOneAnimation from "@/images/loading_stage_one_anim";
import loadingStateTwoAnimation from "@/images/loading_stage_two_anim";
import loadingStateThreeAnimation from "@/images/loading_stage_three_anim";
import loadingStateFourAnimation from "@/images/loading_stage_four_anim";
import backgroundConfetti from "@/images/background_confetti";
import confettiIcon from "@/images/confetti_icon";
import Lottie from "lottie-react";
import { Progress } from "./ui/progress";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@/lib/utils";
import { Button } from "./ui/button";

interface LoadingModalProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  isFinished: boolean;
  redirectUri: string | null;
}

const stages = {
  1: {
    animationData: loadingStateOneAnimation,
    primaryText: "Let's get started",
    secondaryText: "We'll need a few seconds to personalize your experience",
  },
  2: {
    animationData: loadingStateTwoAnimation,
    primaryText: "Something awesome is happening",
    secondaryText: "We're now building your custom interface",
  },
  3: {
    animationData: loadingStateThreeAnimation,
    primaryText: "This is looking really good",
    secondaryText: "Loading your brand style",
  },
  4: {
    animationData: loadingStateFourAnimation,
    primaryText: "We're almost there!",
    secondaryText: "Adding the finishing touches to your demo",
  },
};

export const LoadingModal: React.FC<LoadingModalProps> = ({
  open,
  onOpenChange,
  isFinished,
  redirectUri,
}) => {
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState(1);
  const [showResultPage, setShowResultPage] = useState(false);

  useEffect(() => {
    if (open) {
      setProgress(0);
      const timer = setTimeout(() => setProgress(95), 100); // Small delay to ensure animation starts

      return () => { clearTimeout(timer) }
    }
  }, [open]);

  useEffect(() => {
    if (open && stage < Object.keys(stages).length) {
      const timer = setTimeout(() => setStage(stage + 1), 5000);

      return () => { clearTimeout(timer) }
    }
  }, [stage, open]);

  useEffect(() => {
    if (isFinished) {
      const progressTimer = setTimeout(() => setProgress(100), 100);
      const showResultTimer = setTimeout(() => setShowResultPage(true), 1000); // Delay to allow fade-out animation

      return () => {
        clearTimeout(progressTimer)
        clearTimeout(showResultTimer)
      }
    }
  }, [isFinished]);

  const handleRedirect = () => {
    if (redirectUri) {
      window.location.href = redirectUri;
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={(_) => null}>
      <AlertDialogContent
        className={cn(
          "transition-all duration-1000 ease-loading max-h-[278px]",
          showResultPage &&
            "max-w-full max-h-full w-full h-full sm:rounded-none",
        )}
      >
        <AnimatePresence mode="wait">
          {!showResultPage ? (
            <motion.div
              className="flex justify-center items-center w-full flex-col py-2"
              key="loading-content"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.7, ease: [0.04, 0.0, 0.19, 1.0] }}
            >
              <div className="relative h-[158px] w-full">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.7, ease: [0.04, 0.0, 0.19, 1.0] }}
                  className="absolute top-0 left-0 right-0 mx-auto my-0 w-fit h-[90px]"
                  key={`animation-${stage}`}
                >
                  <Lottie
                    style={{ width: 90, height: 90 }}
                    animationData={stages[stage].animationData}
                    loop={true}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.7, ease: [0.04, 0.0, 0.19, 1.0] }}
                  className="font-bold absolute text-primary text-2xl top-[90px] w-full text-center"
                  key={`primary-text-${stage}`}
                >
                  {stages[stage].primaryText}
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.7, ease: [0.04, 0.0, 0.19, 1.0] }}
                  className="text-primary absolute mt-3 w-full text-center top-[120px]"
                  key={`secondary-text-${stage}`}
                >
                  {stages[stage].secondaryText}
                </motion.div>
              </div>
              <Progress
                className="w-10/12 mt-9"
                value={progress}
                isFinished={isFinished}
              />
              <div className="mt-3" />
            </motion.div>
          ) : (
            <motion.div
              key="redirect-button"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.7, ease: [0.04, 0.0, 0.19, 1.0] }}
              className="flex flex-col items-center justify-center h-full w-full relative"
            >
              <div className="absolute left-0 right-0 w-fit m-in ms-auto me-auto pointer-events-none">
                <Lottie animationData={backgroundConfetti} loop={false} />
              </div>
              <Lottie
                style={{ width: 90, height: 90 }}
                animationData={confettiIcon}
                loop={true}
              />
              <div className="text-3xl font-bold text-primary mt-3">
                Woohoo!
              </div>
              <div className="text-primary mt-2">Your demo is ready to go</div>
              <Button
                size="lg"
                type="submit"
                className="flex max-sm:space-x-[60%] max-lg:w-full mt-6 px-12"
                onClick={handleRedirect}
              >
                View your demo
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </AlertDialogContent>
    </AlertDialog>
  );
};
